import { fg } from '@confluence/feature-gating';

import {
	HOME_OVERVIEW_EXPERIENCE,
	HOME_RECENT_EXPERIENCE,
	HOME_STARRED_EXPERIENCE,
	HOME_WATCHING_EXPERIENCE,
	HOME_DRAFTS_EXPERIENCE,
	HOME_EXPERIENCE,
	HOME_SPACES_EXPERIENCE,
} from './ExperienceName';
import type { ExperienceEvent } from './ExperienceEvent';
import { isStop } from './ExperienceEvent';
import { getExperienceTracker } from './getExperienceTracker';
import type { Experience } from './Experience';
import { collectAny } from './ExperienceEventCollector';

const homeSubExperiences = [
	HOME_OVERVIEW_EXPERIENCE,
	HOME_RECENT_EXPERIENCE,
	HOME_STARRED_EXPERIENCE,
	HOME_WATCHING_EXPERIENCE,
	HOME_DRAFTS_EXPERIENCE,
];

function collectHomeNav3(events: ExperienceEvent[], experience: Experience) {
	const homeExperiences = new Set(homeSubExperiences);

	// stop as soon as any sub-experience is failed or aborted
	experience.stopOn(
		events.find(
			(event) =>
				(event.action === 'taskFail' || event.action === 'taskAbort') &&
				(homeExperiences.has(event.name) || event.name === HOME_SPACES_EXPERIENCE),
		),
	);

	// if any of the view experiences succeed AND the spaces experience succeeds, the root home experience should succeed
	let viewExperienceDidSucceed = false;
	let spacesExperienceDidSucceed = false;
	events.forEach((event) => {
		if (isStop(event)) {
			if (homeExperiences.has(event.name)) {
				viewExperienceDidSucceed = true;
			}

			if (event.name === HOME_SPACES_EXPERIENCE && event.action === 'taskSuccess') {
				spacesExperienceDidSucceed = true;
			}
		}
	});

	if (viewExperienceDidSucceed && spacesExperienceDidSucceed) {
		experience.succeed();
	}
}

export function startHome() {
	getExperienceTracker().start({
		name: HOME_EXPERIENCE,
		collect: fg('confluence_nav_4') ? collectAny(homeSubExperiences) : collectHomeNav3,
	});
}
